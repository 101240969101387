import React, {
  Component,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Loader } from 'semantic-ui-react';
import { User } from 'models';
import { UserService } from 'sections/user';
import { UIMessage } from './UI';
import { USER_MSG } from 'strings';
import { Role } from 'models/Role';

const accessMap = {
  [Role.DESIGN]: [Role.DESIGN],
  [Role.USER]: [Role.DESIGN],
  [Role.MEASURE_LITE]: [Role.MEASURE_LITE, Role.USER, Role.DESIGN],
  [Role.ADMIN]: [Role.ADMIN, Role.USER, Role.DESIGN],
  [Role.SALES]: [Role.SALES, Role.DESIGN, Role.ADMIN, Role.USER],
  [Role.CUST_SERV]: [Role.CUST_SERV, Role.SALES, Role.DESIGN],
  [Role.LATHAM_ADMIN]: [
    Role.LATHAM_ADMIN,
    Role.ADMIN,
    Role.SALES,
    Role.DESIGN,
    Role.CUST_SERV,
    Role.USER,
  ],
  [Role.BAI_ADMIN]: [
    Role.BAI_ADMIN,
    Role.LATHAM_ADMIN,
    Role.ADMIN,
    Role.SALES,
    Role.DESIGN,
    Role.CUST_SERV,
    Role.USER,
  ],
};

export function hasAccess(user: User, role: Role): boolean {
  return accessMap[user.role] && accessMap[user.role].includes(role);
}

export function hasSuperAccess(user?: User): boolean {
  return user
    ? user.role === Role.BAI_ADMIN ||
        user.role === Role.LATHAM_ADMIN ||
        user.role === Role.DESIGN
    : false;
}

interface AuthContextType {
  user?: User;
  setUser: (user: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
}: {
  children?: ReactNode;
}): React.ReactElement {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const ldClient = useLDClient();

  // If we change page, reset the error state
  useEffect(() => {
    setError(null);
  }, [location.pathname]);

  const fetchUser = async () => {
    try {
      const user = await UserService.get();
      setUser(user);
    } catch (err) {
      const error = err as Error;
      //@ts-ignore
      switch (err.statusCode) {
        // For each known error, we show a meaningful message and then logout
        // to clear the user session and send the user back to the login
        case 401:
          // Show UI error based on error recieved from auth0
          if (error.message.toLowerCase() !== 'unauthorized') {
            setError(
              error.message.includes('admin')
                ? USER_MSG.ERROR_PASSWORD_RESET_REQUIRED
                : USER_MSG.ERROR_PASSWORD_CHANGE_REQUIRED
            );
            setTimeout(logout, 5000);
          }
          break;
        case 403:
          // This happens if the user is invalid or has a role which
          // is blacklisted for portal access
          setError(USER_MSG.ERROR_UNAUTH_LOGIN);
          setTimeout(logout, 5000);
          break;
        case 404:
          // This happens if Auth0 and the db are out of sync
          // (user is valid in Auth0 but does not exist in the db)
          setError(USER_MSG.ERROR_USER_NOT_FOUND);
          setTimeout(logout, 5000);
          break;
        case 500:
          setError(USER_MSG.ERROR_GENERIC);
          setTimeout(logout, 5000);
          break;
        case 503:
          // This happens if the endpoint is down
          setError(USER_MSG.ERROR_SERVER);
          setTimeout(logout, 3000);
          break;
        default:
          break;
        // For any other error, we never set the user
        // so the app will redirect to the login
        // (this in part serves as a workaround for a known CORS error upon logout
        // which, by web sec design, does not carry any status code)
      }
    } finally {
      setLoading(false);
    }
  };

  // Check if there is a currently active session
  // when the provider is mounted for the first time
  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (!!ldClient && !!user) {
      ldClient.identify({
        key: user.email,
        name: user.name,
        email: user.email,
      });
    }
  }, [ldClient, user]);

  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      {loading ? (
        <Loader size="massive" content="Loading..." />
      ) : error ? (
        <UIMessage {...error} />
      ) : user ? (
        children
      ) : (
        <RedirectToLogin />
      )}
    </AuthContext.Provider>
  );
}

// Role-based protected route
export function AuthRoute({
  role,
  ...props
}: { role: Role } & RouteProps): React.ReactElement {
  const { user } = useAuth();
  return hasAccess(user as User, role) ? (
    <Route {...props} />
  ) : (
    <UIMessage {...USER_MSG.ERROR_UNAUTH_ROUTE} />
  );
}

function login() {
  const scopes = [
    'openid',
    'profile',
    'email',
    'offline_access',
    'pool:bp:ra:dlr',
    'pool:bp:ro:dlr',
    'pool:bp:ua:dlr',
    'pool:bp:ro:dlrbrand',
    'pool:bp:ra:dlrbrand',
    'pool:bp:ro:dlrpart',
    'pool:bp:co:dlrpart',
    'pool:bp:do:dlrpart',
    'pool:bp:uo:dlrpart',
    'pool:bp:ro:emp',
    'pool:bp:ra:emp',
    'pool:bp:co:emp',
    'pool:bp:uo:emp',
    'pool:bp:do:emp',
    'pool:bp:ca:oplog',
    'pool:bp:ra:oplog',
    'pool:bp:ra:order',
    'pool:bp:ca:order',
    'pool:bp:ua:order',
    'pool:bp:ro:order',
    'pool:bp:ra:swatch',
    'pool:bp:co:prj',
    'pool:bp:ro:prj',
    'pool:bp:uo:prj',
    'pool:bp:ra:prj',
    'pool:bp:ra:prjmedia',
    'pool:bp:ca:prjmedia',
    'pool:bp:da:prjmedia',
    'pool:bp:ra:prjorder',
    'pool:bp:da:prjorder',
    'pool:bp:ua:prjorder',
    'pool:bp:da:prjquote',
    'pool:bp:ra:prjrsrc',
    'pool:bp:xo:submitprj',
    'pool:bp:xo:submitorder',
    'pool:bp:xa:reviewquote',
    'pool:bp:xa:revieworder',
    'pool:bp:xa:emailorder',
    'pool:bp:xa:submitquote',
    'pool:bp:xa:submitorder',
    'pool:bp:ra:prjfeat',
    'pool:bp:ra:prjimg',
    'pool:bp:xa:generatedxf',
    'pool:bp:ua:pooldetail',
    'pool:bp:pa:rsrc',
    'pool:bp:ra:rsrc',
    'pool:bp:da:rsrc',
    'pool:bp:pa:rsrcraw',
    'pool:bp:ra:rsrcraw',
    'pool:bp:ra:rsrcimg',
    'pool:bp:pa:rsrcfile',
    'pool:bp:ro:user',
    'pool:bp:ra:user',
    'pool:bp:po:user',
    'pool:bp:uo:user',
    'pool:bp:ca:user',
    'pool:bp:xa:billable',
    'pool:ma:xa:billable',
    'pool:bp:ra:logreport',
    'pool:ad:debug',
    'pool:ad:xa:copyprj',
    'pool:ad:da:prj',
    'pool:ad:po:dlr',
    'pool:ad:ra:dlrbrand',
  ].join(' ');
  const loginUrl = buildUrl(ApiLoginPathname, getPort(), { scope: scopes });
  window.location.assign(loginUrl);
}

function logout() {
  const logoutUrl = buildUrl(ApiLogoutPathname, getPort());
  window.location.assign(logoutUrl);
}

function callback() {
  const logoutUrl = buildUrl(ApiCallbackPathname, getPort());
  window.location.assign(logoutUrl);
}

const getPort = (): string | undefined => {
  return process.env.REACT_APP_SERVER_PORT;
};

const buildUrl = (
  pathname?: string,
  port?: string,
  queryParams?: Record<string, string>
): string => {
  const newUrl = new URL(window.location.href);
  if (port) {
    newUrl.port = port;
  }
  if (pathname) {
    newUrl.pathname = pathname;
  }
  if (queryParams) {
    for (const key in queryParams) {
      newUrl.searchParams.append(key, queryParams[key]);
    }
  } else {
    newUrl.search = window.location.search;
  }

  return newUrl.toString();
};

const ApiCallbackPathname = '/api/authz/callback';
const ApiLogoutPathname = 'api/authz/logout';
const ApiLoginPathname = '/api/authz/login';

class RedirectToLogin extends Component {
  componentDidMount() {
    if (window.location.pathname === ApiCallbackPathname && getPort()) {
      callback();
    } else {
      login();
    }
  }

  render() {
    return <div>Please wait...</div>;
  }
}
