import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'res/img/logo.svg';
import { routes } from 'routes';
import { useAuth, useUI, hasAccess } from 'services';
import { User } from 'models';
import './SideMenu.scss';
import { Role } from 'models/Role';

export default function SideMenu(): React.ReactElement {
  const { user } = useAuth();
  const { setDevMode } = useUI();
  const [activeItem, setActiveItem] = useState('home');
  const [steps, setSteps] = useState(7);

  const oneSmallStepForADev = useCallback(
    () => setSteps((steps) => steps - 1),
    [setSteps]
  );

  useEffect(() => {
    if (!steps) setDevMode(true);
  }, [steps, setDevMode]);

  return (
    <Menu className="side-menu" borderless vertical>
      <Menu.Item className="side-menu__logo" onClick={oneSmallStepForADev}>
        <div className="logo-container">
          <Logo title="Latham" />
        </div>
      </Menu.Item>
      {routes
        .filter(
          ({ label, role }) =>
            label &&
            (!role || hasAccess(user as User, role)) &&
            !(
              label === 'Company' &&
              (user?.role === Role.CUST_SERV || user?.role === Role.USER)
            )
        )
        .map(({ label, path, icon }, i) => (
          <Menu.Item
            data-cy={label?.toLocaleLowerCase()}
            className="side-menu__item"
            key={i}
            as={Link}
            to={path}
            icon={icon}
            name={label}
            active={activeItem === label}
            onClick={() => setActiveItem(label as string)}
          />
        ))}
      <Menu.Item
        href="https://www.lathampool.com/contact-us/"
        name="Contact"
        icon="mail"
        className="side-menu__item"
        target="_blank"
      />
    </Menu>
  );
}
