import { FC } from 'react';
import { ProjectDetail, ProjectList } from 'sections/projects';
import { CompanyDetails } from 'sections/company';
import { EmployeeList } from 'sections/employee';
import { Admin, AdminCompanyDetail } from 'sections/admin';
import Snowflake from 'sections/snowflake/Snowflake';
import { Role } from 'models/Role';

type Route = {
  path: string;
  component: FC;
  exact?: boolean;
  role?: Role;
  label?: string;
  icon?: string;
};

export const routes: Route[] = [
  {
    path: '/',
    component: ProjectList,
    exact: true,
    label: 'Projects',
    icon: 'map',
  },
  {
    path: '/projects/:id',
    component: ProjectDetail,
  },
  {
    path: '/company',
    component: CompanyDetails,
    exact: true,
    label: 'Company',
    icon: 'truck',
  },
  {
    path: '/employees',
    component: EmployeeList,
    exact: true,
    role: Role.USER,
    label: 'Employees',
    icon: 'users',
  },
  {
    path: '/admin',
    component: Admin,
    exact: true,
    role: Role.LATHAM_ADMIN, // LATHAM_ADMIN is the lowest role that can access the admin page. bright/latham_admin inherit sale's permission
    label: 'Admin',
    icon: 'lock icon',
  },
  {
    path: '/snowflake',
    component: Snowflake,
    exact: true,
    role: Role.BAI_ADMIN, // This is a temporary route for Bright users to load data into snowflake
    label: 'Snowflake',
    icon: 'snowflake icon',
  },
  {
    path: '/admin/company/:id',
    component: AdminCompanyDetail,
  },
];
