import { Project } from 'models';
import React, { useEffect } from 'react';
import { useAuth } from 'services/Auth';
import { OrderContext } from '../../../context/OrderContext';
import { OrderStatus, OrderType, ServerOrder } from '../../../models/Order';
import AccessoriesForm from '../Forms/Partials/Accessories/AccessoriesForm';
import AnchorsForm from '../Forms/Partials/Anchors/AnchorsForm/AnchorsForm';
import FeaturesForm from '../Forms/Partials/Features/FeaturesForm/FeaturesForm';
import { FeaturesFormType } from '../Forms/Partials/Features/FeaturesForm/FeaturesFormSchema';
import ProductForm from '../Forms/Partials/Product/ProductForm';
import ProjectForm from '../Forms/Partials/Project/ProjectForm';
import ShippingForm from '../Forms/Partials/Shipping/ShippingForm';
import SpecForm from '../Forms/Partials/Spec/SpecForm';
import { SpecFormPropTypes } from '../Forms/Partials/Spec/SpecFormSchema';
import {
  getAccessoriesFormData,
  getAnchorsFormData,
  getFeaturesFormData,
  getProductFormData,
  getProjectFormData,
  getShippingFormData,
  getSpecFormData,
} from '../helpers/getFormDataFromState';
import ModalNavigation, { OrderStep } from '../ModalNavigation/ModalNavigation';
import { ReviewOrder } from '../ReviewOrder/ReviewOrder';
import './Stepper.scss';
import { getLathamSpec } from '../helpers/getLathamSpec';
import { Role } from 'models/Role';

export const FIRST_STEP = 0;

type StepperProps = {
  currentStep: number;
  goToStep: (i: number) => void;
  width?: string;
  currentOrder?: ServerOrder;
  deleteOrder: () => void;
  project: Project;
  cancelQuote: () => void;
  hiddenLoading?: boolean;
};

export default function Stepper({
  currentStep = 0,
  goToStep,
  deleteOrder,
  project,
  cancelQuote,
  hiddenLoading = true,
}: StepperProps): React.ReactElement {
  const { state, dispatch } = React.useContext(OrderContext);

  const isNavigationDisabled = state.order?.status === OrderStatus.Ordered;
  const productFormData = getProductFormData(project, state);
  const projectFormData = getProjectFormData(project, state);
  const AccessoriesFormData = getAccessoriesFormData(state);
  const featuresFormData = getFeaturesFormData(state) as FeaturesFormType;
  const anchorsFormData = getAnchorsFormData(state);
  const shippingFormData = getShippingFormData(state);
  const specFormData = getLathamSpec(state) as SpecFormPropTypes;
  const { user } = useAuth();
  const hasEditAccess = user?.role !== Role.DESIGN && user?.role !== Role.SALES;
  const hasReviewAccess = user?.role !== Role.ADMIN && user?.role !== Role.USER;

  useEffect(() => {
    const isLiner = state.project?.type === OrderType.Liner;
    const isCover = state.project?.type === OrderType.Cover;
    let steps: OrderStep[] = [];
    const defaultSteps = [
      'project',
      'product',
      'features',
      'accessories',
      'review',
    ] as OrderStep[];
    const coverQuoteSteps = [
      'project',
      'product',
      'features',
      'anchors',
      'accessories',
      'review',
    ] as OrderStep[];
    const linerQuoteSteps = [
      'project',
      'product',
      'features',
      'accessories',
      'review',
    ] as OrderStep[];
    const coverReviewSteps = [
      'project',
      'product',
      'features',
      'anchors',
      'accessories',
      'review',
      'spec',
    ] as OrderStep[];
    const linerReviewSteps = [
      'project',
      'product',
      'features',
      'accessories',
      'review',
      'spec',
    ] as OrderStep[];
    const orderSteps = [
      'project',
      'product',
      'features',
      'shipping',
      'review',
    ] as OrderStep[];
    const vinlyCoverLinerOrderSteps = [
      'project',
      'product',
      'features',
      'shipping',
      'review',
    ] as OrderStep[];
    const orderedSteps = ['review'] as OrderStep[];

    switch (state.order?.status) {
      case OrderStatus.Ordered: {
        steps = orderedSteps;
        break;
      }
      case OrderStatus.QuotedOptions:
      case OrderStatus.ReadyForOrder: {
        steps = isLiner ? vinlyCoverLinerOrderSteps : orderSteps;
        break;
      }
      case OrderStatus.QuoteInReview: {
        hasReviewAccess
          ? isLiner
            ? (steps = linerReviewSteps)
            : (steps = coverReviewSteps)
          : isLiner
          ? (steps = linerQuoteSteps)
          : (steps = coverQuoteSteps);
        break;
      }
      default: {
        isLiner
          ? (steps = linerQuoteSteps)
          : isCover
          ? (steps = coverQuoteSteps)
          : (steps = defaultSteps);
        break;
      }
    }
    // remove steps that are irrelevant to selected product
    if (isLiner) {
      steps = steps.filter((step) => step !== 'anchors');
    }
    dispatch({ type: 'updateSteps', payload: steps });
  }, [state.order?.status, state.project?.type]);

  const getStepperContent = (): React.ReactElement => {
    switch (state.order?.status) {
      case OrderStatus.Ordered: {
        return (
          <div className="stepper__content">
            <ReviewOrder deleteOrder={deleteOrder} cancelQuote={cancelQuote} />
          </div>
        );
      }
      case OrderStatus.QuotedOptions:
      case OrderStatus.ReadyForOrder: {
        return (
          <div className="stepper__content">
            {currentStep === state.steps.indexOf('project') && (
              <ProjectForm
                hasEditAccess={hasEditAccess}
                data={projectFormData}
              />
            )}
            {currentStep === state.steps.indexOf('product') && (
              <ProductForm
                hasEditAccess={hasEditAccess}
                data={productFormData}
              />
            )}
            {currentStep === state.steps.indexOf('features') && (
              <FeaturesForm
                hasEditAccess={hasEditAccess}
                data={featuresFormData}
              />
            )}
            {currentStep === state.steps.indexOf('shipping') && (
              <ShippingForm
                hasEditAccess={hasEditAccess}
                data={shippingFormData}
              />
            )}
            {currentStep === state.steps.indexOf('review') && (
              <ReviewOrder
                deleteOrder={deleteOrder}
                cancelQuote={cancelQuote}
                hiddenLoading={hiddenLoading}
              />
            )}
          </div>
        );
      }
      case OrderStatus.QuoteInReview: {
        return (
          <div className="stepper__content">
            {currentStep === state.steps.indexOf('product') && (
              <ProductForm
                hasEditAccess={hasEditAccess}
                data={productFormData}
              />
            )}
            {currentStep === state.steps.indexOf('project') && (
              <ProjectForm
                hasEditAccess={hasEditAccess}
                data={projectFormData}
              />
            )}
            {currentStep === state.steps.indexOf('features') && (
              <FeaturesForm
                hasEditAccess={hasEditAccess}
                data={featuresFormData}
              />
            )}
            {currentStep === state.steps.indexOf('anchors') && (
              <AnchorsForm
                hasEditAccess={hasEditAccess}
                data={anchorsFormData}
              />
            )}
            {currentStep === state.steps.indexOf('accessories') && (
              <AccessoriesForm
                hasEditAccess={hasEditAccess}
                data={AccessoriesFormData}
              />
            )}
            {currentStep === state.steps.indexOf('shipping') && (
              <ShippingForm
                hasEditAccess={hasEditAccess}
                data={shippingFormData}
              />
            )}
            {currentStep === state.steps.indexOf('review') && (
              <ReviewOrder
                deleteOrder={deleteOrder}
                cancelQuote={cancelQuote}
              />
            )}
            {currentStep === state.steps.indexOf('spec') && (
              <SpecForm hasEditAccess={hasEditAccess} data={specFormData} />
            )}
          </div>
        );
      }
      case OrderStatus.OrderInReview: {
        return (
          <div className="stepper__content">
            {currentStep === state.steps.indexOf('product') && (
              <ProductForm
                hasEditAccess={hasEditAccess}
                data={productFormData}
              />
            )}
            {currentStep === state.steps.indexOf('project') && (
              <ProjectForm
                hasEditAccess={hasEditAccess}
                data={projectFormData}
              />
            )}
            {currentStep === state.steps.indexOf('features') && (
              <FeaturesForm
                hasEditAccess={hasEditAccess}
                data={featuresFormData}
              />
            )}
            {currentStep === state.steps.indexOf('anchors') && (
              <AnchorsForm
                hasEditAccess={hasEditAccess}
                data={anchorsFormData}
              />
            )}
            {currentStep === state.steps.indexOf('accessories') && (
              <AccessoriesForm
                hasEditAccess={hasEditAccess}
                data={AccessoriesFormData}
              />
            )}
            {currentStep === state.steps.indexOf('shipping') && (
              <ShippingForm
                hasEditAccess={hasEditAccess}
                data={shippingFormData}
              />
            )}
            {currentStep === state.steps.indexOf('review') && (
              <ReviewOrder
                deleteOrder={deleteOrder}
                cancelQuote={cancelQuote}
              />
            )}
            {currentStep === state.steps.indexOf('spec') && (
              <SpecForm hasEditAccess={hasEditAccess} data={specFormData} />
            )}
          </div>
        );
      }
      default: {
        return (
          <div className="stepper__content">
            {currentStep === state.steps.indexOf('product') && (
              <ProductForm
                hasEditAccess={hasEditAccess}
                data={productFormData}
              />
            )}
            {currentStep === state.steps.indexOf('project') && (
              <ProjectForm
                hasEditAccess={hasEditAccess}
                data={projectFormData}
              />
            )}
            {currentStep === state.steps.indexOf('features') && (
              <FeaturesForm
                hasEditAccess={hasEditAccess}
                data={featuresFormData}
              />
            )}
            {currentStep === state.steps.indexOf('anchors') && (
              <AnchorsForm
                hasEditAccess={hasEditAccess}
                data={anchorsFormData}
              />
            )}
            {currentStep === state.steps.indexOf('accessories') && (
              <AccessoriesForm
                hasEditAccess={hasEditAccess}
                data={AccessoriesFormData}
              />
            )}
            {currentStep === state.steps.indexOf('shipping') && (
              <ShippingForm
                hasEditAccess={hasEditAccess}
                data={shippingFormData}
              />
            )}
            {currentStep === state.steps.indexOf('review') && (
              <ReviewOrder
                deleteOrder={deleteOrder}
                cancelQuote={cancelQuote}
              />
            )}
          </div>
        );
      }
    }
  };

  return (
    <div className="stepper">
      <ModalNavigation
        currentStep={currentStep}
        onclick={goToStep}
        disabled={isNavigationDisabled}
      />
      {getStepperContent()}
    </div>
  );
}
