import React, { useEffect, useMemo, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { BaseDeckFormType } from './FeatureSchema';
import './Feature.scss';
import { FeatureFormFieldValidationStatus } from '../FeaturesForm/FeaturesForm';
import {
  MaterialUpgrade,
  SameAsFloor,
  Textured,
  Other,
  linerStepMaterialOptions,
  stepStripeOptions,
  beadOptions,
  differentTreadOptions,
  YES,
  ChooseLaterString,
  beadAllTread,
  beadBackAndRight,
  beadBackAndLeft,
  beadBackAndCorners,
  beadBackWall,
  SameAsBottom,
  ApplyToAllTread,
  TreadValue,
} from './FeatureConstants';
import { ImageType } from 'react-images-uploading';
import { ManagedInput } from 'components/orders/Fields/Input/ManagedInput';
import {
  LinerMaterialTypeOptions,
  LinerMaterials,
  projectOrderState,
} from 'context/OrderContext';
import { FeatureMeasurements } from 'models/Project';
import { FeatureType } from 'models';
import { ManagedSelect } from 'components/orders/Fields/Select/ManagedSelect';
import { Brand } from '../../Product/ProductFormSchema';
import LinerMaterialService from 'sections/orders/LinerMaterialService';
import { ManagedCheckbox } from '../../../../Fields/Checkbox/ManagedCheckbox';
import { object } from 'yup';

interface LinerFeatureProps {
  name: string;
  title: string;
  type: FeatureType;
  notes?: string;
  fieldNotes?: string;
  featureNotes?: string;
  setFeature: (feature: BaseDeckFormType) => void;
  validFields: FeatureFormFieldValidationStatus;
  feature: featureValuesType;
  images: ImageType[];
  archived?: boolean;
  measurement?: FeatureMeasurements;
  project: projectOrderState;
  linerMaterials?: LinerMaterials;
  brand: Brand | undefined;
  isAfterQuote: boolean;
  isReadyOrderForVinlyFeature: boolean;
}

export interface featureValuesType {
  id: string;
  notes?: string;
  fieldNotes?: string;
  featureNotes?: string;
  stepMaterialOption?: string;
  stepMaterial?: string;
  stepFastener?: string;
  stepComment?: string;
  stepStripe?: string;
  stripeWidth?: string;
  differentTreads?: string;
  riserMaterial?: string;
}

export const LinerFeature = ({
  name,
  title,
  type,
  notes,
  fieldNotes,
  featureNotes,
  feature,
  setFeature,
  validFields,
  images,
  linerMaterials,
  archived,
  measurement,
  brand,
  isAfterQuote,
  isReadyOrderForVinlyFeature,
}: LinerFeatureProps) => {
  // Field states
  const [featNotes, setFeatNotes] = useState<string | undefined>(notes);

  const [appNote, setAppNotes] = useState<string | undefined>(fieldNotes);
  const [portalNotes, setPortalNotes] = useState<string | undefined>(
    featureNotes
  );

  const [stepMaterialOption, setStepMaterialOption] = useState(
    feature?.stepMaterialOption === Other
      ? ChooseLaterString
      : SameAsFloor.toLocaleLowerCase().includes(
          feature?.stepMaterialOption?.toLocaleLowerCase() ?? ChooseLaterString
        )
      ? SameAsFloor
      : feature?.stepMaterialOption ?? ChooseLaterString
  );

  const [stepMaterial, setStepMaterial] = useState(
    feature?.stepMaterial ?? stepMaterialOption === SameAsFloor
      ? SameAsBottom
      : undefined
  );
  const [linerMaterialResponse, setLinerMaterialResponse] = useState<any>();
  const [stepFasterOptions, setStepFastenerOptions] = useState<
    { label: any; value: any }[] | []
  >([]);
  const [linerStepMaterialArray, setLinerStepMaterialArray] = useState<
    { label: any; value: any }[] | []
  >([]);
  const [riserStepMaterialOptions, setRiserStepMaterialOptions] = useState<
    { label: any; value: any }[] | []
  >([]);
  const [stepFastener, setStepFastener] = useState(feature?.stepFastener);
  const [stepComment, setStepComment] = useState(feature?.stepComment);
  const [selectedTreaValue, setSelectedTreaValue] = useState('');
  const [updateTreadIndex, setUpdateTreadIndex] = useState(-1);
  const [stepStripe, setStepStripe] = useState(feature?.stepStripe);
  const [differentTreads, setDifferentTreads] = useState(
    feature?.differentTreads
  );
  const [riserMaterial, setRiserMaterial] = useState(feature?.riserMaterial);

  const [galleryVisible, setIsGalleryVisible] = useState(false);
  const gallery = useRef<ImageGallery | null>(null);

  const [visibility, setVisibility] = useState('hidden');
  const [style, setStyle] = useState<any>({
    boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
  });
  const [isSelectBead, setIsSelectBead] = useState(false);
  const [isApplyChecked, setIsApplyChecked] = useState(false);
  const [treadCommentValue, setTreadCommentValue] = useState<
    { key: any; value: any }[]
  >([]);

  const onImageClick = (
    ref: React.MutableRefObject<ImageGallery | null>,
    index: number
  ) => {
    ref.current?.slideToIndex(index);
    ref.current?.fullScreen();
  };

  const updateStepMaterialOption = (value: string) => {
    setStepMaterialOption(value);
    if (value === SameAsFloor) {
      setStepMaterial(SameAsBottom);
    }
    if (value === SameAsFloor && isReadyOrderForVinlyFeature) {
      setFeature({
        ...feature,
        stepMaterial: stepMaterial,
        linerStepMaterial: stepMaterial
          ? getLinerStepMaterial(stepMaterial)
          : undefined,
      } as BaseDeckFormType);
    }
  };
  const getLinerStepMaterialSelectOptions = () => {
    const options = !isReadyOrderForVinlyFeature
      ? linerStepMaterialOptions.filter(
          (option) => option.value !== ChooseLaterString
        )
      : linerStepMaterialOptions;
    return options;
  };
  useEffect(() => {
    if (!name) {
      return;
    }
    setFeature({
      ...feature,
      notes: getNotes(),
      stepMaterialOption: stepMaterialOption,
      stepMaterial: stepMaterial,
      stepFastener: stepFastener,
      stepComment: stepComment,
      stepStripe: stepStripe,
      stripeWidth: '4', // as latham requested, force set the stripe width as 4
      differentTreads: differentTreads,
      riserMaterial: riserMaterial,
      linerStepMaterial: stepMaterial
        ? getLinerStepMaterial(stepMaterial)
        : undefined,
    } as BaseDeckFormType);
  }, [
    featNotes,
    portalNotes,
    stepMaterialOption,
    stepMaterial,
    stepFastener,
    stepComment,
    stepStripe,
    differentTreads,
    riserMaterial,
  ]);
  const getLinerStepMaterial = (stepMaterial: string) => {
    return linerStepMaterialArray.find(
      (material) => material.value === stepMaterial
    );
  };
  const getNotes = () => {
    if (portalNotes && portalNotes != 'null') {
      return appNote + '. ' + portalNotes;
    } else {
      return appNote;
    }
  };

  useEffect(() => {
    if (portalNotes && portalNotes != 'null') {
      setFeatNotes(appNote + '. ' + portalNotes);
    } else {
      setFeatNotes(appNote);
    }
  }, [portalNotes]);

  useEffect(() => {
    const controls = [];
    if (isSelectBead && feature.stepComment && feature.stepComment != 'null') {
      if (feature.stepComment.includes(ApplyToAllTread)) {
        const index = feature.stepComment.indexOf(ApplyToAllTread);
        if (feature.stepComment.length > index) {
          const value = feature.stepComment.substring(0, index);
          refreshCommentValue(value);
          setIsApplyChecked(true);
        }
        return;
      }
      const treadInfoList = feature.stepComment.split('\n');
      if (treadInfoList.length > 1) {
        for (let index = 0; index < treadInfoList.length; index++) {
          const treadInfo = treadInfoList[index].split(':');
          if (treadInfo.length == 2) {
            const value = treadInfo[1].toString();
            controls.push({
              key: index.toString(),
              value: transformBeadTypeForOldOrder(value),
            });
          }
        }
        setTreadCommentValue(controls);
        return;
      }
    }
    refreshCommentValue('');
  }, [isSelectBead]);

  const refreshCommentValue = (value: string) => {
    const controls = [];
    if (
      measurement &&
      measurement.step_treads &&
      (measurement.step_treads.numTreads ?? 0) > 0
    ) {
      for (
        let index = 0;
        index < (measurement.step_treads.numTreads ?? 0);
        index++
      ) {
        controls.push({
          key: index.toString(),
          value: value,
        });
      }
      setTreadCommentValue(controls);
    }
  };

  const transformBeadTypeForOldOrder = (value: String) => {
    switch (value) {
      case 'Bead at all tread & riser junctions':
      case beadAllTread:
        return beadAllTread;
      case 'Bead on back wall only':
      case beadBackWall:
        return beadBackWall;
      case 'Bead on back wall & corners':
      case beadBackAndCorners:
        return beadBackAndCorners;
      case 'Bead on back wall and left side only':
      case beadBackAndLeft:
        return beadBackAndLeft;
      case 'Bead on back wall and right side only':
      case beadBackAndRight:
        return beadBackAndRight;
    }
  };

  useEffect(() => {
    setStepComment('');
    setIsApplyChecked(false);
    if (stepFastener === 'Bead') {
      setIsSelectBead(true);
    } else {
      setIsSelectBead(false);
    }
  }, [stepFastener]);

  useEffect(() => {
    const getMaterials = async () => {
      const materialKeys = linerMaterials ? Object.keys(linerMaterials) : [];
      if (materialKeys.length > 0) {
        const options: LinerMaterialTypeOptions =
          brand && linerMaterials
            ? linerMaterials[materialKeys[0]][brand.id]
            : ({} as LinerMaterialTypeOptions);
        setLinerMaterialResponse(options.StepMaterials.StepMaterial);
      }
    };
    getMaterials();
  }, [linerMaterials]);

  useEffect(() => {
    const getStepFasteners = async () => {
      const data = await LinerMaterialService.getStepFasteners();
      const options = data.getINP15DataOut.INP15List.INP15Record.map(
        (el: any) => ({
          label: el['@_description'],
          value: el['@_description'],
        })
      );
      setStepFastenerOptions(options);
    };
    getStepFasteners();
  }, []);

  useEffect(() => {
    if (stepMaterialOption && linerMaterialResponse) {
      const applicableSwatches = Array.from(
        new Set(
          linerMaterialResponse
            .filter((el: any) => {
              if (stepMaterialOption === Textured) {
                return el['@_IsTextured'] === 'True';
              } else if (stepMaterialOption === SameAsFloor) {
                return el['@_Description'] === SameAsFloor;
              } else if (stepMaterialOption === MaterialUpgrade) {
                // TODO
                return false;
              }
            })
            .map((el: any) => ({
              label: el['@_Description'].includes(')')
                ? el['@_Description'].split(') ')[1]
                : el['@_Description'],
              value: el['@_StepCode'],
            }))
        )
      );

      setLinerStepMaterialArray(
        (applicableSwatches as { label: string; value: string }[]) ?? []
      );
    }
  }, [linerMaterialResponse, stepMaterialOption]);

  useEffect(() => {
    if (stepMaterialOption === SameAsFloor && isReadyOrderForVinlyFeature) {
      setStepMaterial(SameAsBottom);
      setFeature({
        ...feature,
        stepMaterial: stepMaterial,
        linerStepMaterial: stepMaterial
          ? getLinerStepMaterial(stepMaterial)
          : undefined,
      } as BaseDeckFormType);
    }
  }, [stepMaterialOption]);

  useEffect(() => {
    if (linerMaterialResponse) {
      const applicableSwatches = Array.from(
        new Set(
          linerMaterialResponse.map((el: any) => ({
            label: el['@_Description'].includes(')')
              ? el['@_Description'].split(') ')[1]
              : el['@_Description'],
            value: el['@_StepCode'],
          }))
        )
      );

      setRiserStepMaterialOptions(
        (applicableSwatches as { label: string; value: string }[]) ?? []
      );
    }
  }, [linerMaterialResponse, differentTreads]);

  const setHoverStyle = () => {
    setVisibility('visible');
    setStyle({
      cursor: 'pointer',
      background: '#fff',
      border: 'none',
      webkitBoxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      boxShadow: '0 1px 3px 0 #bcbdbd,0 0 0 1px #d4d4d5',
      webkitTransform: 'translateY(-2px)',
      transform: 'translateY(-2px)',
    });
  };

  const clearHoverStyle = () => {
    setVisibility('hidden');
    setStyle({
      boxShadow: '-1px 1px 8px rgb(0 0 0 / 41%)',
    });
  };
  useEffect(() => {
    setFeature({
      ...feature,
      stepMaterial: stepMaterial,
      linerStepMaterial: stepMaterial
        ? getLinerStepMaterial(stepMaterial)
        : undefined,
    } as BaseDeckFormType);
  }, [linerStepMaterialArray]);

  const updateValueByIndex = (index: number, newValue: string) => {
    if (isApplyChecked) {
      setTreadCommentValue((prevDict) => {
        return prevDict.map((e) => {
          e.value = newValue;
          return e;
        });
      });
    } else {
      setTreadCommentValue((prevDict) => {
        return prevDict.map((e) => {
          if (e.key === index.toString()) {
            e.value = newValue;
          }
          return e;
        });
      });
    }
  };

  const updateStepComment = () => {
    let commentString = '';
    if (treadCommentValue.length > 0) {
      if (!isApplyChecked) {
        for (const enumValue in treadCommentValue) {
          const idx = Object.keys(treadCommentValue).indexOf(enumValue);
          const value = Object.values(treadCommentValue)[idx].value;
          if (value?.length > 0) {
            const keyValue = 'T' + (idx + 1).toString();
            commentString += keyValue + ':' + value + '\n';
          }
        }
        setStepComment(commentString);
      } else {
        const first = treadCommentValue[0];
        const comment = first.value + ApplyToAllTread;
        setStepComment(comment);
      }
    }
  };

  useEffect(() => {
    if (updateTreadIndex >= 0) {
      updateValueByIndex(updateTreadIndex - 1, selectedTreaValue ?? '');
    }
  }, [updateTreadIndex, selectedTreaValue]);

  useEffect(() => {
    updateStepComment();
  }, [treadCommentValue]);

  useEffect(() => {
    if (isApplyChecked) {
      const firstTread = Object.values(treadCommentValue)[0];
      const controls = [];
      if (
        measurement &&
        measurement.step_treads &&
        (measurement.step_treads.numTreads ?? 0) > 0
      ) {
        for (
          let index = 0;
          index < (measurement.step_treads.numTreads ?? 0);
          index++
        ) {
          controls.push({
            key: index.toString(),
            value: firstTread.value,
          });
        }
        setTreadCommentValue(controls);
      }
    }
  }, [isApplyChecked]);

  const treadList = () => {
    const list: JSX.Element[] = [];
    Object.keys(treadCommentValue).map((_value, controlKey) => {
      const value = Object.values(treadCommentValue)[controlKey];
      list.push(
        <>
          <div key={controlKey + 1}>
            <ManagedSelect
              index={controlKey + 1}
              label={TreadValue + (controlKey + 1).toString()}
              isValid={value?.value?.length > 0 ? true : false}
              value={value.value}
              getSelectedIndex={setUpdateTreadIndex}
              onChange={setSelectedTreaValue}
              options={beadOptions}
              name="Bead"
              disabled={isApplyChecked && controlKey != 0 ? true : false}
            />
            {controlKey === 0 && treadCommentValue.length > 1 && (
              <ManagedCheckbox
                label="Apply to all treads"
                isValid={true}
                checked={isApplyChecked}
                onChange={setIsApplyChecked}
                disabled={false}
              />
            )}
          </div>
        </>
      );
    });
    return list;
  };

  return (
    <div className="feature">
      <div className="feature__title">{title}</div>
      {images.length > 0 ? (
        <>
          <div
            className="image-container-large"
            onClick={() => onImageClick(gallery, 0)}
            onMouseEnter={() => setHoverStyle()}
            onMouseLeave={() => clearHoverStyle()}
          >
            <img
              src={images[0]?.url}
              className="feature__image"
              style={style}
              onClick={() => onImageClick(gallery, 0)}
            />
            <div
              className="icon-container-large"
              style={{
                visibility: visibility,
              }}
            >
              <i className="zoom-in icon"></i>
            </div>
          </div>
          <ImageGallery
            lazyLoad={true}
            ref={gallery}
            items={images.map((img: any) => ({
              original: img?.url,
              thumbnail: img?.url,
            }))}
            useBrowserFullscreen={false}
            onScreenChange={setIsGalleryVisible}
            thumbnailPosition="top"
            additionalClass={'abs hidden ' + (galleryVisible ? 'show' : '')}
            showPlayButton={false}
            showFullscreenButton={true}
          />
        </>
      ) : (
        <div>No image found.</div>
      )}
      <ManagedInput
        label="Field Notes"
        isValid={true}
        type="text"
        placeholder="Field Notes"
        onChange={setAppNotes}
        value={appNote}
        disabled={true}
      />
      <ManagedInput
        label="Feature Notes"
        isValid={true}
        type="text"
        placeholder="Feature Notes"
        onChange={setPortalNotes}
        value={portalNotes}
        disabled={archived || isReadyOrderForVinlyFeature}
      />

      {measurement?.heightIn && (
        <>
          <span className="managed-input__copy" id="height-copy">
            Height
          </span>
          <span id="height-value">{measurement?.heightIn}</span>
        </>
      )}
      {measurement &&
        measurement.cove_ledge &&
        (type === FeatureType.SafetyLedge || type === FeatureType.Cove) && (
          <>
            <span className="managed-input__copy">Rear Measurement</span>
            <span>{measurement.cove_ledge.backIn}</span>
            <span className="managed-input__copy">Right Measurement</span>
            <span>{measurement.cove_ledge.rightIn}</span>
            <span className="managed-input__copy">Left Measurement</span>
            <span>{measurement.cove_ledge.leftIn}</span>
          </>
        )}
      {type === FeatureType.VinylFeature && (
        <>
          <ManagedSelect
            label="Step Material Option"
            isValid={validFields.stepMaterialOption}
            value={stepMaterialOption}
            onChange={updateStepMaterialOption}
            options={getLinerStepMaterialSelectOptions()}
            name="stepMaterialOption"
            disabled={archived || isAfterQuote || isReadyOrderForVinlyFeature}
          />
          {stepMaterialOption &&
            stepMaterialOption !== ChooseLaterString &&
            stepMaterialOption !== Other &&
            isAfterQuote && (
              <ManagedSelect
                label="Step Material"
                isValid={validFields.stepMaterial}
                value={stepMaterial}
                onChange={setStepMaterial}
                options={linerStepMaterialArray}
                name="stepMaterial"
                disabled={archived || !isReadyOrderForVinlyFeature}
              />
            )}
          <ManagedSelect
            label="Step Fastener Type"
            isValid={validFields.stepFastener}
            value={stepFastener}
            onChange={setStepFastener}
            options={stepFasterOptions}
            name="stepFastener"
            disabled={archived || isReadyOrderForVinlyFeature}
          />
          {isSelectBead &&
          measurement &&
          measurement.step_treads &&
          (measurement.step_treads.numTreads ?? 0) > 0 ? (
            treadList()
          ) : (
            <div></div>
          )}
          <ManagedSelect
            label="White stripe on each tread?"
            isValid={validFields.stepStripe}
            value={stepStripe}
            onChange={setStepStripe}
            options={stepStripeOptions}
            name="stepStripe"
            disabled={archived || isReadyOrderForVinlyFeature}
          />
          {stepStripe === YES && (
            <span className="featureContainer">
              <i className="featureIcon exclamation triangle icon"></i>
              <div className="featureWarning">
                {
                  'Note: A 4" stripe will be applied to the tread and riser junction'
                }
              </div>
            </span>
          )}
        </>
      )}
    </div>
  );
};
