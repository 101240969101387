import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useAuth, useUI } from 'services';
import SnowflakeService from './SnowflakeService';
import { Project, ProjectModel, ProjectStatus } from 'models';
import { SmartTable } from 'components';
import { ReactComponent as Empty } from 'res/img/empty.svg';
import './Snowflake.scss';
import { catchAndThrowErrors } from 'utils';
import { Role } from 'models/Role';

export default function Snowflake(props: any): React.ReactElement {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { user } = useAuth();
  const { showError } = useUI();

  const loadSnowflakeDate = async () => {
    setLoading(true);
    await SnowflakeService.loadSnowflakeData()
      .then((res) => {
        setProjects(res);
        setLoading(false);
        setLoaded(true);
      })
      .catch((err) => {
        setLoading(false);
        catchAndThrowErrors(
          { statusCode: 400, message: err.message },
          showError,
          { title: 'Oops! Something went wrong.', msg: err.message }
        );
      });
  };

  return (
    <div>
      {user?.role === Role.BAI_ADMIN && (
        <>
          <Form loading={loading} className="snowflake-form">
            <h1 className="snowflake-text"> Snowflake </h1>
            <Button onClick={loadSnowflakeDate}>Load Project Data</Button>
          </Form>
          {loaded && (
            <SmartTable
              tableType="projects"
              data={projects}
              model={ProjectModel}
              excludeProps=" notes address.address2 "
              initialSortProp="status"
              transformProps={{
                createdAt: (val) => val.split('T')[0],
                status: (val) => (
                  <div
                    className={
                      'chip ' + (val === ProjectStatus.ACTIVE ? 'primary' : '')
                    }
                  >
                    {val}
                  </div>
                ),
              }}
              empty={<Empty />}
              className="scroll"
            />
          )}
        </>
      )}
    </div>
  );
}
