import { createModel, DataFromSchema } from 'models/Model';
import { Feature, Resource } from 'models';
import { capitalize } from 'utils';
import { ServerOrder } from './Order';

export enum ProjectStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  QUOTE_IN_REVIEW = 'Quoted pending review',
  QUOTE_APPROVED = 'Quoted approved',
  ORDERED = 'Ordered',
  ASSIGNED_TO_BUILDER = 'Assigned to Builder',
}

export const ProjectStatusText = (val: string) => {
  switch (val) {
    case ProjectStatus.ACTIVE:
      return 'Active';
    case ProjectStatus.COMPLETED:
      return 'Completed';
    case ProjectStatus.ORDERED:
      return 'Ordered';
    case ProjectStatus.QUOTE_IN_REVIEW:
      return 'Quoted - Review Pending';
    case ProjectStatus.QUOTE_APPROVED:
      return 'Quote - Approved';
    case ProjectStatus.ASSIGNED_TO_BUILDER:
      return 'Assigned to Builder';
    default:
      return 'Any';
  }
};

export interface PoolShapeOptionsType {
  text: string;
  value: string;
  code: string;
  orientation: string;
  type: 'geometric' | 'freeform';
  image: {
    avatar: boolean;
    src: string;
  };
}

const required = true;

const ProjectSchema = {
  createdAt: { type: 'date', label: 'Created Date' },
  name: { type: 'text', label: 'Name', required },
  dealerId: { type: 'text', label: 'Dealer', required },
  projectOrders: { type: 'text', label: 'Orders' },
  projectQuotes: { type: 'text', label: 'Quotes' },
  notes: { type: 'textarea', label: 'On-Site Info' },
  address: {
    address1: { type: 'text', label: 'Address', required },
    address2: { type: 'text', label: 'Address (cont.)' },
    city: { type: 'text', label: 'City', required },
    state: { type: 'text', label: 'State', required },
    postalcode: { type: 'text', label: 'Zip', required },
    // country    : { type: 'text',  label: 'Country', required },
  },
  customer: {
    name: { type: 'text', label: 'Primary Contact Name', required },
    email: { type: 'email', label: 'Primary Contact Email' },
    phone: { type: 'tel', label: 'Primary Contact Phone' },
  },
  status: {
    required,
    type: 'radio',
    label: 'Status',
    values: ProjectStatus,
    valueDisplay: ProjectStatusText,
  },
} as const;

const ProjectScanSchema = {
  ...ProjectSchema,
  scanTime: { type: 'text', label: 'Scan Time' },
} as const;
// ☝️ this const assertion is needed for DataFromSchema generic to do its magic

export type PoolShape = {
  baseName: string;
  baseCode: string;
  orientation?: string;
  freeform?: 'freeform' | null;
  geometric?: 'geometric' | null;
};

export const PoolShapeType = [
  { label: 'Freeform', value: 'freeform' },
  { label: 'Geometric', value: 'geometric' },
];

export type PoolMeasurements = {
  poolAreaSqFt: number | null;
  poolPerimeterIn: number | null;
  lengthIn: number | null;
  widthIn: number | null;
  coverAreaSqFt: number | null;
  coverPerimeterIn: number | null;
  coverOverlapIn: number | null;
};

type DeckingProperties = {
  numPoints: number;
  lengthAlongPerimeterIn: number;
  minDistanceFromPerimeterIn: number;
  maxDistanceFromPerimeterIn: number;
};

export type FeatureMeasurements = {
  heightIn: number | null;
  numOverWaterPoints: number | null;
  lengthAlongPerimeterIn: number | null;
  distanceFromPerimeterIn: number | null;
  deckingProperties: DeckingProperties[];
  cove_ledge?: CoveLedgeMeasurements | null;
  step_treads?: StepTreads | null;
};

export type StepTreads = {
  areaSqFt: number | null;
  numTreads: number | null;
  step_type: string | null;
};

export type CoveLedgeMeasurements = {
  rightIn: number | null;
  leftIn: number | null;
  backIn: number | null;
};

type ProjectMetaData = {
  id: string;
  userId: string; // id of the user that created
  dealerId: string; // id of the associated dealer
  poolDetails: {
    measurements?: PoolMeasurements;
    surfaceArea: number;
    poolShapeType: 'freeform' | 'geometric';
    poolShape?: PoolShape;
  };
  resources?: Resource[];
  features?: Feature[];
  primaryImageId?: string;
  scans?: any[];
  orders?: ServerOrder[];
  updatedAt?: Date;
};

export type Project = DataFromSchema<typeof ProjectSchema> & ProjectMetaData;

export const ProjectModel = createModel<Project>(ProjectSchema);

export type ProjecScan = DataFromSchema<typeof ProjectScanSchema> &
  ProjectMetaData;

export const ProjectScanModel = createModel<Project>(ProjectScanSchema);
